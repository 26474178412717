@import url(https://fonts.googleapis.com/css2?family=Anton&family=Permanent+Marker&family=Titillium+Web:ital,wght@0,400;0,600;1,400&display=swap);

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Root {
  display: flex;
}

.Content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin: auto;
  align-content: space-around;
  font-size: 16px;
  padding: 0 10px 10px 10px;
}

img {
  height: 300px;
  width: 300px;
  margin: auto;
}


@media (min-width: 901px) {
  .Content  {
    width: 900px;
  }
}
