.Root {
  display: flex;
}

.Content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin: auto;
  align-content: space-around;
  font-size: 16px;
  padding: 0 10px 10px 10px;
}

img {
  height: 300px;
  width: 300px;
  margin: auto;
}


@media (min-width: 901px) {
  .Content  {
    width: 900px;
  }
}